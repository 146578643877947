(function () {
	'use strict';

	angular
		.module('sidebar')
		.directive('textFilterBox', ['$timeout', textFilterBox])
		.directive('textFilter', [textFilter])
		.directive('statusesFilter', [statusesFilter])
		.directive('resourcesFilter', [resourcesFilter])
		.directive('projectsFilter', [projectsFilter])
		.directive('contactsFilter', [contactsFilter])
		.directive('classNameDisplay', ['utilities', classNameDisplay])
		.directive('monitorScroll', [monitorScroll]);

	function textFilterBox($timeout) {
		var textFilterTimeout;

		return {
			restrict: 'EA',
			scope: {
				filterText: '=',
				onChange: '&',
				clearFilter: '&',
			},

			link: function (scope, element, attrs) {
				var containerElementClass = attrs.containerElementClass;
				var parentElementClass = attrs.parentElementClass;
				var standardDelay = 250;
				var longDelay = 700;
				var delay =
					attrs.longDelay && attrs.longDelay !== 'false'
						? longDelay
						: standardDelay;
				var containerElement;
				var containerHeight;
				var parentElement;
				var useHeight;
				var parentHeight;
				var minHeightApplied;
				scope.hasFocus = attrs.hasFocus;
				scope.hideIcon = attrs.hideIcon;
				scope.placeholder = attrs.placeholderText;

				if (containerElementClass) {
					containerElement = document.querySelector(
						containerElementClass
					);
				}

				scope.callChanged = function () {
					//increase timeout if we have a lot of horizon columns make sure that if the column delay is shorter than the standard delay we default to standard
					$timeout.cancel(textFilterTimeout);

					textFilterTimeout = $timeout(function () {
						if (containerElementClass) {
							parentElement = parentElementClass
								? document.querySelector(parentElementClass)
								: null; // To prevent lost element
							containerHeight = containerElement.offsetHeight + 2; // adding the 2 for border height
							parentHeight = parentElement
								? parentElement.offsetHeight
								: 0;
							useHeight =
								containerHeight > parentHeight
									? parentHeight
									: containerHeight;
							if (
								!minHeightApplied ||
								minHeightApplied < useHeight
							) {
								containerElement.style.minHeight =
									useHeight + 'px';
								minHeightApplied = useHeight;
							}
						}

						// Create a function that can return a parameter to the passed in scope function
						scope.onChange()(scope.filterText);
					}, delay);
				};
			},

			template:
				'<div class="search-wrapper" ng-class="{\'closed\':titleClosed}">' +
				'<i ng-hide="hideIcon" class="fa fa-search list-selector-icon dbk_icon_search"></i>' +
				'<textarea focus-me has-focus={{hasFocus}}' +
				'type="text" ' +
				'class="ng-valid ng-dirty ng-valid-parse ng-touched" ' +
				'autoresize min-height="12" rows="1" spellcheck="false" ' +
				'ng-model="filterText" ' +
				'ng-keyUp="callChanged()" ' +
				'ng-attr-placeholder="{{placeholder | translate}}">' +
				'</textarea>' +
				'<i class="fa fa-times list-selector-icon dbk_icon_times" ' +
				'ng-click="clearFilter()" ' +
				'ng-class="{\'clearFilters\': filterText.length > 0}">' +
				'</i>' +
				'</div>',
		};
	}

	function textFilter() {
		return {
			restrict: 'EA',
			templateUrl: 'app/sidebar/filters/filters-text.html',
		};
	}

	function statusesFilter() {
		return {
			restrict: 'EA',
			templateUrl: 'app/sidebar/filters/filters-statuses.html',
		};
	}

	function resourcesFilter() {
		return {
			restrict: 'EA',
			templateUrl: 'app/sidebar/filters/filters-resources.html',
		};
	}

	function projectsFilter() {
		return {
			restrict: 'EA',
			templateUrl: 'app/sidebar/filters/filters-projects.html',
		};
	}

	function contactsFilter() {
		return {
			restrict: 'EA',
			templateUrl: 'app/sidebar/filters/filters-contacts.html',
		};
	}

	function classNameDisplay(utilities) {
		return {
			restrict: 'EA',
			scope: {
				className: '=',
			},
			link: function (scope, element, attrs) {
				var prefix = attrs.prefix;
				scope.$watch('className', function (newValue, oldValue) {
					scope.classDisplay = newValue
						? utilities.stringToClass(newValue, prefix)
						: '';
					scope.leader = newValue
						? attrs.leader + ': '
						: attrs.leader;
				});
			},
			template:
				'<span>{{leader}}</span><span class="background-message">{{classDisplay}}</span>',
		};
	}

	function monitorScroll() {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				var nativeElement = element[0];
				var lastScrollPosition;

				var hasScrolled;
				// Add event listener for scroll
				nativeElement.addEventListener('scroll', onScroll);

				// Remove event listener when the scope is destroyed
				scope.$on('$destroy', function (e) {
					nativeElement.removeEventListener('scroll', onScroll);
				});

				function onScroll(e) {
					if (hasScrolled && scope.calendar.preventFilterScroll) {
						// Set scroll Posiiton
						nativeElement.scrollTo(0, lastScrollPosition);
					}
					hasScrolled = true;
					scope.calendar.preventFilterScroll = null;
					lastScrollPosition = nativeElement.scrollTop;
				}
			},
		};
	}
})();
